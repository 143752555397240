import React from 'react'
import styled from 'styled-components'
import Image from 'components/Image'
import themeColor from 'utils/themeColor'

// ===================================================
// STYLES
// ===================================================

const Container = styled('div')({
  width: '100%',
  background: ({ background, theme }) => themeColor(background, null, theme),
})

const Row = styled('div')({
  maxWidth: 1600,
  margin: 'auto',
  padding: 16,
  display: 'flex',
  '@media (max-width:899.95px)': {
    flexDirection: 'column',
    maxWidth: 400,
  },
})

const ProductContainer = styled('div')({
  flex: 1,
})

const ImageContainer = styled('div')({
  position: 'relative',
  height: ({ aspectRatio }) => (aspectRatio === 'natural' ? 'initial' : 0),
  paddingBottom: ({ aspectRatio }) => aspectRatio,
  '&>*': {
    position: ({ aspectRatio }) =>
      aspectRatio === 'natural' ? 'initial' : 'absolute',
    inset: ({ aspectRatio }) => (aspectRatio === 'natural' ? 'initial' : 0),
  },
})

const TextContainer = styled('div')({
  padding: 24,
  color: ({ color, theme }) => themeColor(color, 'white', theme),
})

const Heading = styled('span')({
  display: 'block',
  textTransform: 'uppercase',
  fontSize: 21,
  fontWeight: 'bold',
})

const FeaturesList = styled('ul')({
  paddingLeft: 0,
  listStyleType: 'none',
})

const Feature = styled('li')({
  position: 'relative',
  fontSize: 18,
  lineHeight: '30px',
  display: 'flex',
  alignItems: 'center',
})

const BulletPoint = styled('span')({
  position: 'relative',
  display: 'inline-block',
  padding: 4,
  marginRight: 16,
  background: ({ color, theme }) => themeColor(color, 'white', theme),
})

// ===================================================
// COMPONENTS
// ===================================================

const Product = ({
  image,
  name,
  features = [],
  aspectRatio = 'natural',
  color,
}) => (
  <ProductContainer>
    <ImageContainer aspectRatio={aspectRatio}>
      <Image
        background={aspectRatio !== 'natural'} // crop images to shape when not "natural"
        src={image?.filename}
        alt={image?.alt}
      />
    </ImageContainer>
    <TextContainer color={color}>
      <Heading>{name}</Heading>
      {!features.length ? null : (
        <FeaturesList>
          {features.map(feature => (
            <Feature key={`feature-${name}-${feature}`}>
              <BulletPoint />
              <span>{feature.text}</span>
            </Feature>
          ))}
        </FeaturesList>
      )}
    </TextContainer>
  </ProductContainer>
)

const StoryblokProductRow = ({ data: { background, products = [] } }) => (
  <Container background={background}>
    <Row>
      {products.map(product => (
        <Product {...product} key={`product-row-${product.name}`} />
      ))}
    </Row>
  </Container>
)

export default StoryblokProductRow
